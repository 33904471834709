// FK Roman Bold
@font-face {
  font-family: "FK Roman";
  src: url("../fonts/FKRomanStandard-Bold.otf") format("opentype");
}

// Inter-regular - latin
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  // IE9 Compat Modes
  src: url("../fonts/inter-v3-latin-regular.eot");
  src: local(""),
    // IE6-IE8
    url("../fonts/inter-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    // Super Modern Browsers
    url("../fonts/inter-v3-latin-regular.woff2") format("woff2"),
    // Modern Browsers
    url("../fonts/inter-v3-latin-regular.woff") format("woff"),
    // Safari, Android, iOS
    url("../fonts/inter-v3-latin-regular.ttf") format("truetype"),
    // Legacy iOS
    url("../fonts/inter-v3-latin-regular.svg#Inter") format("svg");
}

// Inter-500 - latin
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  // IE9 Compat Modes
  src: url("../fonts/inter-v3-latin-500.eot");
  src: local(""),
    // IE6-IE8
    url("../fonts/inter-v3-latin-500.eot?#iefix") format("embedded-opentype"),
    // Super Modern Browsers
    url("../fonts/inter-v3-latin-500.woff2") format("woff2"),
    // Modern Browsers
    url("../fonts/inter-v3-latin-500.woff") format("woff"),
    // Safari, Android, iOS
    url("../fonts/inter-v3-latin-500.ttf") format("truetype"),
    // Legacy iOS
    url("../fonts/inter-v3-latin-500.svg#Inter") format("svg");
}

// Inter-600 - latin
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  // IE9 Compat Modes
  src: url("../fonts/inter-v3-latin-600.eot");
  src: local(""),
    // IE6-IE8
    url("../fonts/inter-v3-latin-600.eot?#iefix") format("embedded-opentype"),
    // Super Modern Browsers
    url("../fonts/inter-v3-latin-600.woff2") format("woff2"),
    // Modern Browsers
    url("../fonts/inter-v3-latin-600.woff") format("woff"),
    // Safari, Android, iOS
    url("../fonts/inter-v3-latin-600.ttf") format("truetype"),
    // Legacy iOS
    url("../fonts/inter-v3-latin-600.svg#Inter") format("svg");
}
