/** Reveal Colors */

// Primary colors
$purple: #7e64ff;
$darkPurple: #5a28c3;
$lightPurple: #f5f3ff;
$midnight: #0a151b;
$midnight500: #828888;
$comet: #1c2430;
$darkPigeon: #2a3343;
$pigeon: #5a617f;
$greyReveal: #8d8f9d;
$grey500: #f8f8f8;
$darkTaupe: #cac0b7;
$taupe: #e2deda;
$taupe500: #ece8e1;
$taupe250: #f3f1eb;
$offWhite: #f6f2e8;
$white: #ffffff;
$ivory: #fafaf6;
$green: #23d49f;
$appGreen: #23d49f;
$red500: #d05984;
$red600: "#BF3D6C";
$red100: "#F5E7E8";
$red50: "#F7EDED";
// Secondary colors
$wine: #a30145;
$pictonBlue: #30bced;
$orange: #fc5130;
$pistachio: #98ce00;
$elm: #218380;
$cornflowerBlue: #7494ea;
$gold: #d7af70;
$londonHue: #b58db6;
$coral: #7494ea;

:export {
  // Primary colors
  purple: $purple;
  darkPurple: $darkPurple;
  lightPurple: $lightPurple;
  midnight: $midnight;
  midnight500: $midnight500;
  comet: $comet;
  darkPigeon: $darkPigeon;
  pigeon: $pigeon;
  greyReveal: $greyReveal;
  grey500: $grey500;
  darkTaupe: $darkTaupe;
  taupe: $taupe;
  taupe500: $taupe500;
  taupe250: $taupe250;
  offWhite: $offWhite;
  white: $white;
  ivory: $ivory;
  green: $green;
  appGreen: $appGreen;
  red500: $red500;
  red600: $red600;
  red100: $red100;
  red50: $red50;
  // Secondary colors
  wine: $wine;
  pictonBlue: $pictonBlue;
  orange: $orange;
  pistachio: $pistachio;
  elm: $elm;
  cornflowerBlue: $cornflowerBlue;
  gold: $gold;
  londonHue: $londonHue;
  coral: $coral;
}
