@import "styles/colors.scss";
@import "styles/fonts.scss";

html {
  height: 100%;
}

body {
  margin: 0 !important;
  padding: 0;
  // TODO: Change to `"Inter", sans-serif;` after/just before styleguide is released.
  font-family: sans-serif;
  height: 100%;

  .chilipiper-popup-loading {
    background-image: url(../public/images/reveal-spinner.gif) !important;
    border-radius: 50px;
  }
}

#root {
  height: 100%;
}

.tox-notifications-container {
  display: none !important;
}

.mce-content-body {
  margin: 8px 0;
}

.mce-content-body > p {
  margin: 0;
}

.mce-edit-focus {
  outline-color: #18a999;
}

.bounce {
  -webkit-animation: bounce 2s;
  animation: bounce 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}

@-webkit-keyframes shadowpulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(37, 131, 253, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(37, 131, 253, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(37, 131, 253, 0);
  }
}
@keyframes shadowpulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(37, 131, 253, 0.4);
    box-shadow: 0 0 0 0 rgba(37, 131, 253, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 5px rgba(37, 131, 253, 0.2);
    box-shadow: 0 0 0 5px rgba(37, 131, 253, 0.2);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(37, 131, 253, 0);
    box-shadow: 0 0 0 0 rgba(37, 131, 253, 0);
  }
}

.grecaptcha-badge {
  bottom: 50px !important;
  left: 0 !important;
  width: 70px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
